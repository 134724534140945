import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _08a164b1 = () => interopDefault(import('../src/pages/cookie-policy.vue' /* webpackChunkName: "pages/cookie-policy" */))
const _071382ee = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _0fbd312e = () => interopDefault(import('../src/pages/manage-booking/index.vue' /* webpackChunkName: "pages/manage-booking/index" */))
const _151259de = () => interopDefault(import('../src/pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _f258fba4 = () => interopDefault(import('../src/pages/receipt.vue' /* webpackChunkName: "pages/receipt" */))
const _6aac2722 = () => interopDefault(import('../src/pages/search.vue' /* webpackChunkName: "pages/search" */))
const _273ae29e = () => interopDefault(import('../src/pages/search-booking/index.vue' /* webpackChunkName: "pages/search-booking/index" */))
const _5481f96e = () => interopDefault(import('../src/pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _1d994aec = () => interopDefault(import('../src/pages/travel-extras.vue' /* webpackChunkName: "pages/travel-extras" */))
const _f1d29af4 = () => interopDefault(import('../src/pages/unsupported-browser.vue' /* webpackChunkName: "pages/unsupported-browser" */))
const _416d5c5e = () => interopDefault(import('../src/pages/manage-booking/cancel/index.vue' /* webpackChunkName: "pages/manage-booking/cancel/index" */))
const _0635080e = () => interopDefault(import('../src/pages/manage-booking/modify.vue' /* webpackChunkName: "pages/manage-booking/modify" */))
const _7d4cb694 = () => interopDefault(import('../src/pages/manage-booking/receipt.vue' /* webpackChunkName: "pages/manage-booking/receipt" */))
const _57c96692 = () => interopDefault(import('../src/pages/manage-booking/update-details.vue' /* webpackChunkName: "pages/manage-booking/update-details" */))
const _7d72daad = () => interopDefault(import('../src/pages/select-extras/product/index.vue' /* webpackChunkName: "pages/select-extras/product/index" */))
const _cb598b12 = () => interopDefault(import('../src/pages/manage-booking/cancel/receipt.vue' /* webpackChunkName: "pages/manage-booking/cancel/receipt" */))
const _8853c970 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cookie-policy",
    component: _08a164b1,
    name: "cookie-policy"
  }, {
    path: "/maintenance",
    component: _071382ee,
    name: "maintenance"
  }, {
    path: "/manage-booking",
    component: _0fbd312e,
    name: "manage-booking"
  }, {
    path: "/privacy-policy",
    component: _151259de,
    name: "privacy-policy"
  }, {
    path: "/receipt",
    component: _f258fba4,
    name: "receipt"
  }, {
    path: "/search",
    component: _6aac2722,
    name: "search"
  }, {
    path: "/search-booking",
    component: _273ae29e,
    name: "search-booking"
  }, {
    path: "/terms-and-conditions",
    component: _5481f96e,
    name: "terms-and-conditions"
  }, {
    path: "/travel-extras",
    component: _1d994aec,
    name: "travel-extras"
  }, {
    path: "/unsupported-browser",
    component: _f1d29af4,
    name: "unsupported-browser"
  }, {
    path: "/manage-booking/cancel",
    component: _416d5c5e,
    name: "manage-booking-cancel"
  }, {
    path: "/manage-booking/modify",
    component: _0635080e,
    name: "manage-booking-modify"
  }, {
    path: "/manage-booking/receipt",
    component: _7d4cb694,
    name: "manage-booking-receipt"
  }, {
    path: "/manage-booking/update-details",
    component: _57c96692,
    name: "manage-booking-update-details"
  }, {
    path: "/select-extras/product",
    component: _7d72daad,
    name: "select-extras-product"
  }, {
    path: "/manage-booking/cancel/receipt",
    component: _cb598b12,
    name: "manage-booking-cancel-receipt"
  }, {
    path: "/",
    component: _8853c970,
    name: "index"
  }, {
    path: "/dedicated",
    component: _8853c970,
    meta: {"auth0Flow":"dedicated"},
    name: "dedicated-wl-index"
  }, {
    path: "/dedicated/search",
    component: _6aac2722,
    meta: {"auth0Flow":"dedicated"},
    name: "dedicated-wl-search"
  }, {
    path: "/dedicated/select-extras/product",
    component: _7d72daad,
    meta: {"auth0Flow":"dedicated"},
    name: "dedicated-wl-select-extras"
  }, {
    path: "/dedicated/manage-booking/modify",
    component: _0635080e,
    meta: {"auth0Flow":"dedicated"},
    name: "dedicated-wl-modify"
  }, {
    path: "/dedicated/receipt",
    component: _f258fba4,
    meta: {"auth0Flow":"dedicated"},
    name: "dedicated-wl-receipt"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
